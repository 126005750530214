<template>
  <div>
    <div class="container-xxl">
      <div class="row align-items-center vh-100">
        <div class="col-12 text-center">
          <div class="card card-login align-middle">
            <div class="card-body" style="text-align: center">
              <h1 class="card-title mb-5">{{ title }}</h1>
              <div>
                <div class="input-container">
                  <input
                    v-model="inputEntry"
                    :type="inputType"
                    class="form-control m-auto"
                    :placeholder="placeholder"
                    @keydown.enter="submitAction(inputEntry)"
                  />
                </div>
                <div class="button-container">
                  <button
                    class="btn btn-primary login-btn"
                    :disabled="!inputEntry"
                    @click="submitAction(inputEntry)"
                  >
                    Enter
                  </button>
                </div>
                <p v-if="errorMessage !== ''" class="invalid-feedback d-block">
                  {{ errorMessage }}
                </p>
                <!-- Use a named slot for terms agreement -->
                <div v-if="$slots.terms" class="terms-agreement">
                  <slot name="terms"></slot>
                </div>
              </div>
              <img
                src="../assets/logo.png"
                alt="Pixelview Logo"
                width="240px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'placeholder', 'errorMessage', 'submitAction', 'inputType'],
  data() {
    return {
      inputEntry: '',
    };
  },
};
</script>

<style scoped>
.card-login {
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 30px;
  background-color: #091825;
  display: inline-block;
  border-radius: 20px;
  color: white;
}

.card-title {
  font-weight: 500;
  font-size: 1.8em;
  margin-bottom: 20px;
  margin-top: 30px;
  color: white;
}

.card-text {
  font-size: 16px;
  color: white;
}

.input-container,
.button-container {
  margin-bottom: 15px;
}

.button-container button {
  color: white !important;
}

.form-control {
  width: 342px;
  height: 56px;
  border: 1px solid grey;
  background-color: transparent;
  border-radius: 12px;
  color: white;
}

.form-control::placeholder {
  color: grey;
  opacity: 1;
  padding: 10px;
}

.form-control-password {
  color: white;
  padding-left: 10px;
  -webkit-text-security: disc;
}

.login-btn {
  width: 342px;
  height: 56px;
  background: #2e58c7 !important;
  border-radius: 12px;
  color: white;
  font-weight: 600;
  font-size: 0.95em;
  letter-spacing: 1.5px;
}

@media (max-width: 991px) {
  .form-control {
    width: 290px;
  }

  .card {
    padding-left: 15px;
    padding-right: 15px;
  }

  .login-btn {
    width: 290px;
  }
}

.terms-agreement {
  font-size: 12px;
  color: #6c757d;
  margin-top: 15px;
}

.terms-agreement .terms-link {
  color: #007bff !important;
  text-decoration: none !important;
}

.terms-agreement .terms-link:hover {
  text-decoration: underline !important;
}
</style>
