<template>
  <div class="controls" :class="{ 'light-background': !isDarkBackground }">
    <div class="devices">
      <div id="v-step-0" class="device-button unknown-block">
        <img
          v-if="audioOutputDevices.length"
          @click="openAudioOutputList"
          class="down-arrow-right"
          :src="isDarkBackground ? downArrow : downArrowDark"
        />
        <button @click="toggleMute" class="microphone">
          <template v-if="!videoChatAudioMuted">
            <img
              height="20"
              class="speaker-icon"
              :src="isDarkBackground ? speaker : speakerDark"
              alt=""
              title="Mute video chat audio"
            />
          </template>
          <template v-else>
            <img
              height="20"
              class="speaker-icon"
              :src="isDarkBackground ? speakerOff : speakerOffDark"
              alt=""
              title="Unmute video chat audio"
            />
          </template>
        </button>
        <div
          v-click-outside="onClickOutside"
          v-if="audioOutputList && audioOutputDevices.length"
          class="position-absolute microphone-list"
          :class="{ 'light-text': isDarkBackground }"
        >
          <div v-if="audioOutputDevices.length" class="mt-3">
            <div>
              <img
                class="icon speaker-icon"
                :src="isDarkBackground ? speaker : speakerDark"
                alt=""
              />
              Speaker
            </div>
            <div
              v-for="(device, index) in audioOutputDevices"
              :key="device.deviceId"
              :value="device.deviceId"
              class="form-check"
            >
              <input
                :checked="device.deviceId === selectedAudioOutputDevices"
                @click="setSpeaker(device.deviceId)"
                class="form-check-input"
                type="radio"
                name="speaker"
                :id="'flexRadioDefault2' + device.deviceId"
              />
              <label
                class="form-check-label"
                :for="'flexRadioDefault2' + device.deviceId"
              >
                {{ device.label || 'Unnamed Device' }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="device-button microphone-block">
        <img
          v-if="audioDevices.length || audioOutputDevices.length"
          @click="openMicList"
          class="down-arrow-right"
          :src="isDarkBackground ? downArrow : downArrowDark"
        />
        <button @click="handleAudioClickInternal" class="microphone">
          <template v-if="participant.audio">
            <img
              class="icon"
              :src="isDarkBackground ? micOn : micOnDark"
              alt=""
              title="Mute microphone"
            />
          </template>
          <template v-else>
            <img
              class="icon"
              :src="isDarkBackground ? micOff : micOffDark"
              alt=""
              title="Unmute microphone"
            />
          </template>
        </button>
        <div
          v-click-outside="onClickOutside"
          v-if="
            microphoneList && (audioDevices.length || audioOutputDevices.length)
          "
          class="position-absolute microphone-list"
          :class="{ 'light-text': isDarkBackground }"
        >
          <div v-if="audioDevices.length">
            <div>
              <img
                class="icon"
                :src="isDarkBackground ? micOn : micOnDark"
                alt=""
              />
              Microphone
            </div>
            <div
              v-for="(device, index) in audioDevices"
              :key="device.deviceId"
              :value="device.deviceId"
              class="form-check"
            >
              <input
                :checked="device.deviceId === selectedAudioDevice"
                @click="setMicrophone(device.deviceId)"
                class="form-check-input"
                type="radio"
                name="microphone"
                :id="'flexRadioDefault1' + device.deviceId"
              />
              <label
                class="form-check-label"
                :for="'flexRadioDefault1' + device.deviceId"
              >
                {{ device.label || 'Unnamed Device' }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="device-button leave-block">
        <button class="leave" @click="leaveCall">
          <div class="button-div" @click="endCall()">
            <img class="icon" :src="leave" alt="" title="Leave video call" />
          </div>
        </button>
      </div>

      <div class="device-button video-block">
        <img
          v-if="videoDevices.length"
          @click="openVideoList"
          class="down-arrow-left"
          :src="isDarkBackground ? downArrow : downArrowDark"
        />
        <button @click="handleVideoClickInternal" class="video">
          <template v-if="participant.video">
            <img
              class="icon"
              :src="isDarkBackground ? videoOn : videoOnDark"
              alt=""
              title="Turn off webcam"
            />
          </template>
          <template v-else>
            <img
              class="icon"
              :src="isDarkBackground ? videoOff : videoOffDark"
              alt=""
              title="Turn on webcam"
            />
          </template>
        </button>
        <div
          v-click-outside="onClickOutside"
          v-if="videoListVar && videoDevices.length"
          class="position-absolute video-list"
          :class="{ 'light-text': isDarkBackground }"
        >
          <div>
            <div>
              <img
                class="icon"
                :src="isDarkBackground ? videoOn : videoOnDark"
                alt=""
              />
              Camera
            </div>
            <div
              v-for="(device, index) in videoDevices"
              :key="device.deviceId"
              :value="device.deviceId"
              class="form-check"
            >
              <input
                :checked="device.deviceId === selectedVideoDevice"
                @click="setVideo(device.deviceId)"
                class="form-check-input"
                type="radio"
                name="microphone"
                :id="'flexRadioDefault3' + device.deviceId"
              />
              <label
                class="form-check-label"
                :for="'flexRadioDefault3' + device.deviceId"
              >
                {{ device.label || 'Unnamed Device' }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="device-button share-block">
        <div v-if="supportsScreenshare">
          <button
            class="screen-share"
            :disabled="disableScreenShare"
            @click="handleScreenshareClick"
          >
            <img
              class="icon"
              :src="screenShare ? screenShareCloseIcon : screenShareIcon"
              alt=""
              :title="
                !screenShare ? 'Start Screen Sharing' : 'Stop Screen Sharing'
              "
            />
          </button>
        </div>

        <div v-else>
          <button></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import daily from '@daily-co/daily-js';
import vClickOutside from 'click-outside-vue3';

import leave from '../../assets/leave_call.svg';
import micOff from '../../assets/mic_off.svg';
import micOn from '../../assets/mic_on.svg';
import screenShareIcon from '../../assets/screenshare.png';
import screenShareCloseIcon from '../../assets/screenshare-close.png';
import videoOff from '../../assets/vid_off.svg';
import videoOn from '../../assets/vid_on.svg';
import speaker from '../../assets/speaker.svg';
import speakerOff from '../../assets/speaker-off.png';
import micOffDark from '../../assets/mic_off_dark.png';
import micOnDark from '../../assets/mic_on_dark.png';
import videoOffDark from '../../assets/vid_off_dark.png';
import videoOnDark from '../../assets/vid_on_dark.png';
import speakerDark from '../../assets/speaker_dark.png';
import speakerOffDark from '../../assets/speaker_off_dark.png';
import downArrow from '../../assets/down-arrow.png';
import downArrowDark from '../../assets/down-arrow-dark.png';
import { mapGetters } from 'vuex';

export default {
  name: 'CallControls',
  props: [
    'participant',
    'handleVideoClick',
    'handleAudioClick',
    'handleScreenshareClick',
    'leaveCall',
    'disableScreenShare',
  ],
  data() {
    return {
      leave,
      micOn,
      micOff,
      screenShareIcon,
      screenShareCloseIcon,
      videoOn,
      speaker,
      speakerOff,
      videoOff,
      supportsScreenshare: false,
      selectedAudioDevice: 'default',
      selectedVideoDevice: 'default',
      selectedAudioOutputDevices: 'default',
      videoListVar: false,
      microphoneList: false,
      audioOutputList: false,
      videoDevices: [],
      audioDevices: [],
      audioOutputDevices: [],
      steps: [
        {
          target: '#v-step-0', // We're using document.querySelector() under the hood
          content: 'Click speaker to enable audio',
        },
      ],
      micOffDark,
      micOnDark,
      videoOffDark,
      videoOnDark,
      speakerDark,
      speakerOffDark,
      downArrow,
      downArrowDark,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    videoChatAudioMuted: {
      get() {
        return this.$store.getters.videoChatAudioMuted;
      },
      set(value) {
        this.$store.commit('setVideoChatAudioMuted', value);
      },
    },
    ...mapGetters(['screenShare']),
    isDarkBackground() {
      const rgb = this.hexToRgb(this.backgroundColor);
      return rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 <= 186;
    },
    backgroundColor() {
      return this.$store.state.backgroundColor;
    },
  },
  mounted() {
    // Only show the screen share button if the browser supports it
    this.supportsScreenshare = daily.supportedBrowser().supportsScreenShare;
    this.enumerateDevices();
    this.popupItem = this.$el;
    this.timeoutVideoCall();
  },
  methods: {
    toggleMute() {
      this.videoChatAudioMuted = !this.videoChatAudioMuted;
    },
    timeoutVideoCall() {
      // Close videochat after 12 hours in case someone forgets to leave
      setTimeout(() => {
        this.leaveCall();
      }, 43200000);
    },
    endCall() {
      window.emitter.emit('joinCall', false);
      this.$store.commit('setScreenShare', null);
    },

    async handleVideoClickInternal() {
      await this.$props.handleVideoClick();
      setTimeout(() => {
        this.enumerateDevices();
      }, 2000);
    },

    async handleAudioClickInternal() {
      await this.$props.handleAudioClick();
      setTimeout(() => {
        this.enumerateDevices();
      }, 2000);
    },

    enumerateDevices() {
      console.log('Enumerating devices');
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          this.videoDevices = devices.filter(
            (device) => device.kind === 'videoinput'
          );
          this.audioDevices = devices.filter(
            (device) => device.kind === 'audioinput'
          );
          this.audioOutputDevices = devices.filter(
            (device) => device.kind === 'audiooutput'
          );
          console.log(
            'Audio output devices:',
            JSON.stringify(this.audioOutputDevices)
          );

          this.selectedVideoDevice = this.videoDevices.length
            ? this.videoDevices[0].deviceId
            : '';
          this.selectedAudioDevice = this.audioDevices.length
            ? this.audioDevices[0].deviceId
            : '';
          this.selectedAudioOutputDevices = this.audioOutputDevices.length
            ? this.audioOutputDevices[0].deviceId
            : '';
        })
        .catch((error) => {
          console.error('Error enumerating devices:', error);
        });
    },

    openMicList() {
      this.microphoneList = true;
    },

    openAudioOutputList() {
      this.audioOutputList = true;
    },

    openVideoList() {
      this.videoListVar = true;
    },

    setMicrophone(microphoneId) {
      this.selectedAudioDevice = microphoneId;
      window.emitter.emit('setMicrophone', microphoneId);
    },

    setSpeaker(speakerId) {
      this.selectedAudioOutputDevices = speakerId;
      window.emitter.emit('setSpeaker', speakerId);
    },

    setVideo(videoId) {
      this.selectedVideoDevice = videoId;
      window.emitter.emit('setVideo', videoId);
    },

    onClickOutside(event) {
      this.videoListVar = false;
      this.microphoneList = false;
      this.audioOutputList = false;
    },

    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    },
  },
};
</script>

<style scoped>
.controls {
  position: absolute;
  bottom: 0px !important;
  left: 8px;
  justify-content: space-between;
  display: flex;
  width: calc(100% - 16px);
}

.light-background .icon:not(.microphone .icon):not(.video .icon) {
  filter: invert(1);
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
button:not(.leave) {
  margin: 0 4px;
  width: 36px;
  height: 26px;
}
button.leave {
  background-color: #f63135;
  opacity: 0.85;
  padding: 8px;
  border-radius: 50%;
  top: -44px;
  right: 0;
  left: 0;
  width: 40px;
  height: 40px;
  margin: auto;
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
}
button:disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.icon {
  height: 24px;
}

.speaker-icon {
  width: 20px;
  height: 20px;
}

.button-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.leave .icon {
  height: 24px;
  width: 24px;
}

.draw-icon {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 15px;
  z-index: 2;
}

.devices {
  margin: auto;
}

.device-button {
  display: inline-block;
}

.microphone-block,
.unknown-block {
  position: relative;
  margin-right: 60px;
}

.share-block,
.video-block {
  position: relative;
  margin-left: 60px;
}

.microphone-list .form-check,
.video-list .form-check {
  cursor: pointer;
  margin-top: 8px;
}

.microphone-list,
.video-list {
  background: #273540;
  border-radius: 5px;
  color: #000; /* Default text color for light background */
  z-index: 1000; /* Add this line to increase the z-index */
}

.light-text {
  color: #fff; /* Text color for dark background */
}

.microphone-list {
  width: 550px;
  text-align: left;
  padding: 12px;
  bottom: 50px;
  z-index: 1000; /* Update this line to increase the z-index */
}

.video-list {
  width: 400px;
  text-align: left;
  bottom: 50px;
  padding: 12px;
  z-index: 1000; /* Add this line to increase the z-index */
}

.down-arrow-left {
  cursor: pointer;
  position: absolute;
  left: 30px;
  top: -15px;
  width: 18px;
  height: 18px;
}

.down-arrow-right {
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: -15px;
  width: 18px;
  height: 18px;
}

@media (max-width: 600px) {
  .unknown-block {
    margin-right: 40px;
  }

  .share-block {
    margin-left: 40px;
  }

  .microphone-block {
    margin-right: 40px;
  }

  .video-block {
    margin-left: 40px;
  }
}

@media (max-width: 500px) {
  .unknown-block {
    margin-right: 30px;
  }

  .share-block {
    margin-left: 30px;
  }

  .microphone-block {
    margin-right: 30px;
  }

  .video-block {
    margin-left: 30px;
  }
}

@media (max-width: 487px) {
  .unknown-block {
    margin-right: 28px;
  }

  .share-block {
    margin-left: 28px;
  }

  .microphone-block {
    margin-right: 28px;
  }

  .video-block {
    margin-left: 28px;
  }
}

@media (max-width: 467px) {
  .unknown-block {
    margin-right: 20px;
  }

  .share-block {
    margin-left: 20px;
  }

  .microphone-block {
    margin-right: 20px;
  }

  .video-block {
    margin-left: 20px;
  }
}

@media (max-width: 437px) {
  .unknown-block {
    margin-right: 15px;
  }

  .share-block {
    margin-left: 15px;
  }

  .microphone-block {
    margin-right: 15px;
  }

  .video-block {
    margin-left: 15px;
  }
}

@media (max-width: 427px) {
  .unknown-block {
    margin-right: 12px;
  }

  .share-block {
    margin-left: 12px;
  }

  .microphone-block {
    margin-right: 12px;
  }

  .video-block {
    margin-left: 12px;
  }
}

@media (max-width: 397px) {
  .unknown-block {
    margin-right: 10px;
  }

  .share-block {
    margin-left: 10px;
  }

  .microphone-block {
    margin-right: 10px;
  }

  .video-block {
    margin-left: 10px;
  }

  .microphone-block button {
    margin: 0;
  }

  .video-block button {
    margin: 0;
  }
}

@media (max-width: 387px) {
  .unknown-block {
    margin-right: 8px;
  }

  .share-block {
    margin-left: 8px;
  }

  .microphone-block {
    margin-right: 8px;
  }

  .video-block {
    margin-left: 8px;
  }
}

@media (max-width: 377px) {
  .unknown-block {
    margin-right: 5px;
  }

  .share-block {
    margin-left: 5px;
  }

  .microphone-block {
    margin-right: 5px;
  }

  .video-block {
    margin-left: 5px;
  }
}

@media (max-width: 350px) {
  .unknown-block {
    margin-right: 3px;
  }

  .share-block {
    margin-left: 3px;
  }

  .microphone-block {
    margin-right: 3px;
  }

  .video-block {
    margin-left: 3px;
  }
}
</style>
