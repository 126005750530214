<template>
  <div v-if="show" class="tooltip" id="custom-tooltip">
    Click to enable audio
  </div>
</template>

<script>
export default {
  name: 'CustomTooltip',
  data() {
    return {
      show: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.show = false;
    }, 10000);
  },
};
</script>

<style>
.tooltip-wrapper {
  width: 180px;
  -webkit-transform: translateZ(0);
  -webkit-font-smoothing: antialiased;
  float: left !important;
}

.tooltip-wrapper .tooltip {
  background: #050e16;
  bottom: 100%;
  color: #fff;
  border-radius: 11px;
  display: block;
  left: 5px;
  margin-bottom: 39px;
  opacity: 0;
  padding: 10px;
  pointer-events: none;
  position: absolute;
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

.tooltip-wrapper .tooltip:before {
  bottom: -20px;
  content: ' ';
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

.tooltip-wrapper .tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid #050e16 10px;
  bottom: -10px;
  content: ' ';
  height: 0;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  width: 0;
}

.tooltip {
  opacity: 1 !important;
  pointer-events: auto !important;
  -webkit-transform: translateY(0px) !important;
  -moz-transform: translateY(0px) !important;
  -ms-transform: translateY(0px) !important;
  -o-transform: translateY(0px) !important;
  transform: translateY(0px) !important;
}
</style>
