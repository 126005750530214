<template>
  <div class="chat-footer-file">
    <button
      class="chat-footer-file__action"
      @click="openFile"
    >
      <img src="../../assets/attach-file.png" alt="file"/>
    </button>
    <input
      id="inputFile"
      ref="inputFile"
      accept="image/*"
      multiple
      type="file"
      class="chat-footer-file__input"
      @change="onFileSelected"
    />
    <Transition>
      <div
        v-if="Object.keys(this.selectedFiles).length"
        class="chat-footer-file__selected"
        :class="{
          'chat-footer-file__selected--docked': docked && !showScrollDown,
          'chat-footer-file__selected--scroll-down': docked && showScrollDown
         }"
      >
        <div
          class="chat-footer-file__selected-item selected-item"
          v-for="(file, index) in selectedFiles"
          :key="index"
        >
         <div class="selected-item__preview" @click="onSelectImage(file,index)">
           <img :src="getURLFile(file)" alt="image"/>
         </div>
          <button
            @click="deleteFile(index)"
            class="selected-item__delete"
          >
            <img src="../../assets/close.png" alt="close" />
          </button>
        </div>
      </div>
    </Transition>
    <ChatImagePreview
      v-if="0"
      :selected-overlay-image="selectedOverlayImage"
      @close-overlay="selectedOverlayImage = null"
    />
    <ChatPreviewSlider
      v-if="imagesPreview.images.length"
      :images="imagesPreview.images"
      :active-index="imagesPreview.selectedIndex"
      @close-overlay="resetSelectedImages"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ChatImagePreview from "@/components/textchat/ChatImagePreview.vue";
import ChatPreviewSlider from "@/components/textchat/ChatPreviewSlider.vue";

export default {
  name: "ChatFooterFile",
  components: {ChatPreviewSlider, ChatImagePreview},
  props: {
    docked: {
      type: Boolean,
      default: false
    },
    showScrollDown: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedOverlayImage: null,
      imagesPreview: {
        images: [],
        selectedIndex: null
      }
    }
  },
  computed: {
    ...mapGetters({
      selectedFiles: 'chat/getSelectedFiles'
    })
  },
  methods: {
    openFile()  {
      this.$refs.inputFile.value = ""
      this.$refs.inputFile.click();
    },
    onFileSelected (event)  {
      const selected = Array.from(event.target.files);
      this.$store.dispatch('chat/setSelectedFiles', selected)
      this.$emit('file-selected', this.$store.getters['chat/getSelectedFiles']);
    },
    onSelectImage(file, index) {
      this.imagesPreview.selectedIndex = index;
      this.imagesPreview.images = this.selectedFiles.map(el => this.getURLFile(el))
    },
    getURLFile (file) {
      return URL.createObjectURL(file);
    },
    deleteFile(index) {
      const totalFiles = this.selectedFiles;
      totalFiles.splice(index, 1);
      this.$store.dispatch('chat/setSelectedFiles', totalFiles);
    },
    resetSelectedImages() {
      this.imagesPreview = {
        images: [],
        selectedIndex: null
      }
    }
  }
}

</script>

<style lang="scss">
.chat-footer-file {
  &__action {
    padding: 0;
    img {
      width: 1.3rem;
      height: 1.3rem;
    }
  }

  &__input {
    display: none;
  }

  &__selected {
    position: absolute;
    background: #6b7785;
    padding: 0.25rem;
    border-radius: 8px;
    bottom: 5rem;
    left: 50%;
    overflow-y: auto;
    transform: translateX(-50%);
    max-width: calc(100% - 2rem);
    max-height: 16rem;
    display: inline-grid;
    grid-template-columns: repeat(auto-fit, minmax(3rem, 1fr));
    gap: 0.25rem;

    .selected-item {
      position: relative;
      width: 3rem;
      height: 3rem;
      &__preview {
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;
        filter: brightness(0.5);
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 0.5rem;
        }
      }
      &__delete {
        position: absolute;
        top: -0.25rem;
        right: 0.1rem;
        padding: 0;
        transition: all 0.2s;
        img {
          width: 1rem;
          height: 1rem;
        }

        &:hover {
          transform: scale(1.1);
        }

      }
    }

    &--docked {
      bottom: 9rem;
    }
    &--scroll-down{
      bottom: 12.5rem;
    }
  }
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
