<template>
  <div v-click-outside="closeEmoji" class="chat-emoji-picker">
    <button
      class="chat-emoji-picker__button"
      @click="togglePopup"
    >
      <img src="@/assets/smile.png" alt="smile" />
    </button>
    <transition name="ops-slide-left">
      <EmojiPicker
        v-if="isVisible"
        native
        theme="dark"
        class="chat-emoji-picker__component"
        @select="onSelectEmoji"
      />
    </transition>
  </div>
</template>

<script>
import EmojiPicker from "vue3-emoji-picker";
import "vue3-emoji-picker/css";
import {mapGetters} from "vuex";
export default {
  name: "ChatFooterEmojiPicker",
  emits: ['select-emoji'],
  components: {
    EmojiPicker
  },
  computed: {
    ...mapGetters({
      isVisible: 'chat/getEmojiPickerVisible'
    })
  },
  methods: {
    togglePopup () {
      this.$store.dispatch('chat/setEmojiPickerVisible', !this.isVisible)
    },
    closeEmoji () {
      this.$store.dispatch('chat/setEmojiPickerVisible', false)
    },
    onSelectEmoji (event) {
      const emojiData = { emoji: event.i, unicode: event.u };
      this.closeEmoji()
      this.$emit("select-emoji", emojiData);
    },
  }
}
</script>

<style lang="scss">
.chat-emoji-picker {
  width: fit-content;
  height: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &__component {
    position: absolute;
    left: -15rem;
    bottom: 2rem;
    background: #050E16;

    .v3-sticky {
      background: #050E16 !important;
    }
  }
}

.chat-emoji-picker__button {
  padding: 0;
  transition: all;
  transition-duration: 200ms;
  border-radius: 50%;
  flex-shrink: 0;
  img {
    width: 1.25rem;
    height: 1.25rem;
    display: block;
    transition: all;
    transition-duration: 200ms;
  }
}
</style>
