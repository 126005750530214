<template>
  <div id="app" :style="{ backgroundColor: backgroundColor }">
    <div id="nav">
      <router-link to="/" />
    </div>
    <router-view />
    <OpenInAppDrawer v-if="getDrawerApp" />
  </div>
</template>

<style>
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

#app {
  min-height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

<script>
import { computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import OpenInAppDrawer from '@/components/drawers/OpenInAppDrawer.vue';
import { isIOS } from 'mobile-device-detect';
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    OpenInAppDrawer,
  },
  computed: {
    ...mapGetters(['getDrawerApp']),
  },
  setup() {
    const store = useStore();

    const backgroundColor = computed(() => store.state.backgroundColor);

    onMounted(() => {
      if (isIOS) {
        store.commit('setDrawerApp', true);
      }
    });

    watch(
      backgroundColor,
      (newColor) => {
        document.body.style.backgroundColor = newColor;
      },
      { immediate: true }
    );

    return {
      backgroundColor,
    };
  },
};
</script>
