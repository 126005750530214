<script>
export default {
  name: "ChatImagePreview",
  props: ['selectedOverlayImage'],
  emits: ['close-overlay']
}
</script>

<template>
  <Teleport to="body">
    <div
      v-if="selectedOverlayImage" class="file-message__preview preview"
    >
      <div
        class="preview__overlay"
        @click.stop="$emit('close-overlay')"
      />

      <div class="preview__wrapper">
        <button
          class="preview__close"
          @click.stop="$emit('close-overlay')"
        >
          <img src="../../assets/close.png" alt="close" />
        </button>
        <img
          class="preview__item"
          :src="selectedOverlayImage"
          alt="file"
        />
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss">
.preview {
  position: fixed;
  left: 0;
  top:0;
  width: 100vw;
  height: 100vh;
  z-index: 1001;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__overlay {
    position: absolute;
    background: rgba(24, 24, 24, 0.5);
    height: 100%;
    width: 100%;
  }
  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 35rem;
    max-height: 35rem;
    min-width: 15rem;
  }

  &__close {
    border-radius: 50%;
    padding: 8px;
    position: absolute;
    top: -1.5rem;
    right: -1.5rem;

    img {
      width: 2rem;
      height: 2rem;
    }
  }

  &__item {
    object-fit: cover;
    max-width: 35rem;
    max-height: 35rem;
    min-width: 15rem;
    animation: onShowPreview 0.3s cubic-bezier(0.31, 0.09, 0.21, 0.81);
  }
}

@keyframes onShowPreview {
  0% {
    transform-origin: center;
    transform: scale(0);
  }
  100% {
    transform-origin: center;
    transform: scale(1);
  }
}

</style>