<template>
  <div class="d-flex justify-content-center">
    <div class="col-md-6">
      <div class="card rounded-0 shadow">
        <div class="card-body">
          <div class="form-group">
            <h6>
              {{ message }}
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Message',
  props: ['message'],
};
</script>
