<template>
  <div class="conversation">
    <button
      v-if="showTextChat && !docked"
      class="conversation__hide-chat"
      @click="closeCometChat"
    >
      <img src="../../assets/close.png" alt="close" />
    </button>
    <div class="conversation__chat-wrapper">
      <div ref="chatRef" class="conversation__chat">
        <DynamicScroller
          ref="scroller"
          class="scroller"
          :items="messages"
          :min-item-size="54"
          :emit-update="true"
          :emit-resize="true"
          :buffer="3500"
        >
          <template #default="{ item, index, active }">
            <DynamicScrollerItem
              v-if="item.id"
              :item="item"
              :active="active"
              :data-index="index"
              :data-active="active"
              class="conversation__chat-message chat-message"
              :class="item?.state?.author === userID ? 'chat-message-mine' : 'chat-message-default'"
            >
              <div :key="index" class="chat-message__wrapper">
                <div v-if="0" class="chat-message__author">
                  <img src="../../assets/play.png" alt="test"/>
                </div>
                <div class="chat-message__content">
                  <div class="chat-message__name">{{ item?.state?.name }}:</div>
                  <ChatMessageFile v-if="item?.state?.medias?.length" :message-data="item"/>
                  <div v-else class="chat-message__message">{{ item?.state?.body }}</div>
                  <div class="chat-message__timestamp">{{ formatDate(item?.state?.timestamp) }}</div>
                </div>
              </div>
            </DynamicScrollerItem>
          </template>
        </DynamicScroller>
      </div>
      <transition>
        <button
          v-if="!isOnBottomScroll"
          class="conversation__scroll-down"
          :class="{'conversation__scroll-down--docked': docked}"
          @click="scrollToBottom"
        >
          <img src="@/assets/arrow-down.png" alt="scroll-down" />
        </button>
      </transition>
    </div>
    <div class="conversation__input send-message">
      <input
        @keyup.enter="sendMessage"
        v-model="messageText"
        placeholder="Enter your message"
        autocomplete="nope"
        ref="inputSend"
        type="text"
        name="message"
      />
      <div class="send-message__actions">
        <ChatFooterFile :show-scroll-down="!isOnBottomScroll" :docked="docked" @file-selected="selectedFiles = $event"/>
        <ChatFooterEmojiPicker @select-emoji="onSelectEmoji"/>
        <button class="send-message__action" @click="sendMessage">
          <img
            v-if="!isBusySend"
            class="send-message__send"
            src="@/assets/send-message.png"
            alt="send"
          />
          <img v-else class="send-message__loading" src="@/assets/loading.png" alt="loading" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import ChatFooterFile from "@/components/textchat/ChatFooterFile.vue";
import ChatMessageFile from "@/components/textchat/ChatMessageFile.vue";
import {mapGetters} from "vuex";
import ChatSkeleton from "@/components/ChatSkeleton.vue";
import ChatFooterEmojiPicker from "./ChatFooterEmojiPicker.vue";
import { DynamicScroller, DynamicScrollerItem, RecycleScroller } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import {nextTick} from "vue";

export default {
  components: {ChatFooterEmojiPicker, ChatSkeleton, ChatMessageFile, ChatFooterFile, DynamicScroller, DynamicScrollerItem},
  props: ['conversation', 'userID', 'docked'],
  data() {
    return {
      messages: [],
      messageText: '',
      isSignedInUser: false,
      participantsMap: {},
      isBusySend: false,
      chatScrollHeight: 0,
      isOnBottomScroll: true
    };
  },
  async mounted() {
    // Each message has an author property that is the userID of the user that sent it
    // However each participant has a name that so we map that name to a new name property
    // on the message to display it in a user friendly way

    // Get participants to save the author = name mapping
    await this.getParticipants();

    // Parse messages and add the name property
    this.conversation.getMessages().then( async (newMessages) => {
      newMessages.items = newMessages.items.map((message) => {
        return {
          ...message,
          state: {
            ...message.state,
            name: this.participantsMap[message.state.author],
          },
        };
      });
      this.messages = [...this.messages, ...newMessages.items].map(el => ({...el, id: el.state.sid}));
      this.scrollToBottom()
    });

    // Parse new messages and add the name property
    this.conversation.on('messageAdded', (message) => {
      const isMineMessage = message.state.author === this.userID
      if(!isMineMessage && this.showTextChat) {
        this.isOnBottomScroll = this.checkIsUserOnBottomScroll()
      }
      message = {
        ...message,
        state: {
          ...message.state,
          name: this.participantsMap[message.state.author],
        },
      };
      this.messages = [...this.messages, message].map(el => ({...el, id: el.state.sid}));
      if (!this.$store.getters['showTextChat']) {
        this.unreadTextMessage = true;
      }
      if(this.isOnBottomScroll) {
        this.scrollToBottom()
      }
    });

    // Add new participants to the map so we can get their name when they send a message
    this.conversation.on('participantJoined', async (participant) => {
      this.participantsMap[participant.identity] = participant.attributes.name;
    });
    document.querySelector('.vue-recycle-scroller')?.addEventListener('scroll', this.onScrollChat)
  },
  unmounted() {
    document.querySelector('.vue-recycle-scroller')?.removeEventListener('scroll', this.onScrollChat)
  },
  computed: {
    ...mapGetters({
      selectedFiles: 'chat/getSelectedFiles',
    }),
    showTextChat: {
      get() {
        return this.$store.state.showTextChat;
      },
      set(value) {
        this.$store.commit('setShowTextChat', value);
      },
    },
    unreadTextMessage: {
      get() {
        return this.$store.state.unreadTextMessage;
      },
      set(value) {
        this.$store.commit('setUnreadTextMessage', value);
      },
    },
    textChatHiddenByUser: {
      get() {
        return this.$store.state.textChatHiddenByUser;
      },
      set(value) {
        this.$store.commit('setTextChatHiddenByUser', value);
      },
    },
  },
  methods: {
    async getParticipants() {
      let participants = await this.conversation.getParticipants();
      participants.forEach((participant) => {
        this.participantsMap[participant.identity] =
          participant.attributes.name;
      });
    },
    async sendMessage() {
      if(this.isBusySend) return;
      if(Object.keys(this.selectedFiles)?.length) {
        await this.sendMediaMessage()
      } else {
        this.messageText && await this.sendMessageText()
      }
      this.messageText = ''
      setTimeout(() => {
        this.scrollToBottom()
      }, 500)
    },
    async sendMediaMessage () {
      this.isBusySend = true
      let mediaOptions = []
      for(let file of this.selectedFiles) {
        const blob = new Blob([file], { type: 'text/plain' })
        mediaOptions.push({
          contentType: file.type,
          filename: file.name,
          media: blob
        })
      }
      const prepearedMessage = await this.conversation.prepareMessage()
      mediaOptions.forEach(media => {
        prepearedMessage.addMedia(media)
      })
      prepearedMessage.setBody(this.messageText);
      const builded = await prepearedMessage.build()
      await builded.send()
      this.$store.dispatch('chat/setSelectedFiles', [])
      this.isBusySend = false
    },
    async sendMessageText () {
      this.conversation.sendMessage(this.messageText).then(() => {
        this.messageText = '';
      });
    },
    formatDate: function (date) {
      const d = dayjs(date)
      return d.format('HH:mm')
    },
    onSelectEmoji(event) {
      this.messageText += event.emoji;
      this.$refs.inputSend.focus();
    },
    closeCometChat() {
      this.$store.commit('setShowTextChat', false);
      this.textChatHiddenByUser = false;
      setTimeout(() => {
        this.$emit('handle-resize');
      }, 700);
      setTimeout(() => {
        this.$emit('handle-resize');
      }, 3000);
    },
    scrollToBottom() {
      this.$refs.scroller?.scrollToBottom()
      this.isOnBottomScroll = true
    },
    checkIsUserOnBottomScroll () {
      const scrollTop = Math.round(this.$refs?.scroller?.$el?.scrollTop) + Math.round(this.$refs?.scroller?.$el?.clientHeight);
      const totalScrollArea = this.$refs?.scroller?.$el?.scrollHeight
      const totalMessages = document.querySelectorAll('.vue-recycle-scroller__item-view')
      if(!totalScrollArea || !totalMessages) return;
      // offset height
      const lastMessageHeight = totalMessages[totalMessages.length - 1]?.clientHeight
      // is user on bottom
      return scrollTop >= totalScrollArea - lastMessageHeight
    },
    onScrollChat() {
      (!this.isOnBottomScroll && this.showTextChat) && (this.isOnBottomScroll = this.checkIsUserOnBottomScroll())
    }
  },
  watch: {
    showTextChat(value) {
      value && setTimeout(() => {
        this.scrollToBottom()
      }, 500)
    }
  }
}
</script>

<style lang="scss">

.conversation {
  border-radius: 8px;
  position: relative;
  background: #050E16;
  display: flex;
  flex-direction: column;

  &__hide-chat {
    position: absolute;
    right: -0.75rem;
    top: -0.75rem;
    z-index: 1;
    img {
      width: 24px;
      height: 24px;
    }
  }

  &__chat-wrapper {
    padding: 1.25rem 0.25rem;
    position: relative;
  }

  &__chat {
    padding: 0 0.75rem;
    display: flex;
    row-gap: 0.5rem;
    height: 35vw;
    flex-direction: column;
  }

  &__input {
    border-top: 1px solid #374459;
    display: flex;
    padding: 1rem;
    column-gap: 0.5rem;

    input {
      width: 100%;
      background: transparent;
      color: #fff;
      border: none;
      &::placeholder {
        color: #fff;
      }

      &:focus {
        outline: none;
      }
    }
  }

  &__scroll-down {
    position: absolute;
    bottom: 1rem;
    right: 50%;
    transform: translateX(50%);
    background: #374459;
    border-radius: 50%;
    padding: 0;
    width: 2rem;
    height: 2rem;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 1rem;
      height: 1rem;
    }

    &--docked {
      bottom: 5rem;
      width: 3rem;
      height: 3rem;

      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}

.send-message {
  &__actions {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
  }

  &__send {
    width: 1.25rem;
    height: 1.25rem;
  }

  &__loading {
    width: 1.5rem;
    height: 1.5rem;
    animation: spin 1s linear infinite;
  }

  &__action {
    padding: 0;
  }
}

.chat-message {
  display: flex;
  padding: 0.5rem 0;

  &__wrapper {
    max-width: 50%;
    color: #fff;
    width: fit-content;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
  }

  &__content {
    margin-right: 8px;
    word-break: break-word;
  }

  &__author {
    img {
      width: 24px;
      height: 24px;
      border: 1px solid #fff;
      border-radius: 50%;
    }
  }

  &__message {
    text-align: left;
    color: #fff;
    border-radius: 1rem;
    padding: 0.5rem;
    background-color: #374459;
    font-size: 1rem;
  }
  &__name {
    color: #fff;
    padding-right: 8px;
    font-size: 14px;
    display: inline-block;
    white-space: nowrap;
  }

  &__timestamp {
    font-size: 11px;
    margin-top: 0.25rem;
  }

  &-mine {
    justify-content: flex-start;
    .chat-message__wrapper {
      .chat-message__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  &-default {
    justify-content: flex-end;

    .chat-message__wrapper {
      flex-direction: row-reverse;

      .chat-message__content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }
}


::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #51555b;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  background: #184059;
  border-radius: 12px;
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  } to {
      transform: rotate(360deg);
    }
}
</style>
