import {
  createWebHistory,
  createRouter
} from 'vue-router'

import Login from '../views/Login.vue'
import NotFoundPage from '../views/NotFoundPage.vue'
import Home from '../views/Home.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'NotFoundPage',
      component: NotFoundPage,
    },
    // Login route is always hit first and then it
    // redirects to Home after login
    {
      path: '/:sessionID',
      name: 'Login',
      component: Login,
    },
    {
      path: '/:sessionID',
      name: 'Home',
      component: Home,
    }
  ]
})

export { router }
