
const clickOutSide= {
  mounted(
    el,
    binding
  ) {
    const handleClickOutside = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };
    el.clickOutsideEvent = handleClickOutside;
    document.addEventListener("click", handleClickOutside);
  },
  unmounted(el) {
    document.removeEventListener(
      "click",
      el.clickOutsideEvent
    );
    el.clickOutsideEvent = undefined;
  },
};

export default clickOutSide;
