<template>
  <div class="main-player">
    <div
      v-show="showStillImage"
      style="width: 100%; height: 100%; position: relative"
    >
      <img
        :src="stillImage"
        @error="
          stillImage =
            'https://static.pixelview.io/default_thumbnails/empty-thumbnail.jpg'
        "
        style="width: 100%; height: 100%; object-fit: cover"
      />
      <div
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        "
      >
        <p class="text-white">
          Waiting for stream to resume <span class="dot">.</span
          ><span class="dot">.</span><span class="dot">.</span>
        </p>
      </div>
    </div>
    <div v-show="!showStillImage" id="sldp-player"></div>
    <div class="tooltip-wrapper">
      <custom-tooltip></custom-tooltip>
    </div>
  </div>
</template>

<script>
import CustomTooltip from "../components/CustomTooltip.vue";

export default {
  name: "MainPlayer",
  props: {
    streamUrl: String,
  },
  components: { CustomTooltip },
  data() {
    return {
      showStillImage: false,
      playerReloads: 0,
      sldpPlayer: null,
      buffer: 0,
      connectionRetries: 0,
      loadRetryInterval: null,
      stillImage:
        "https://static.pixelview.io/default_thumbnails/empty-thumbnail.jpg",
      imageFeatchInterval: null,
      volume: 20,
      initalLoad: new Date().getTime(),
      stillImageReloadFailures: 0,
    };
  },
  mounted() {
    this.initPlayer();
    this.imageFeatchInterval = setInterval(() => {
      this.fetchStillImage();
    }, 7000);
    setTimeout(() => {
      this.$emit("loading-video", false);
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.imageFeatchInterval);
    clearInterval(this.loadRetryInterval);
  },
  methods: {
    async fetchStillImage() {
      const imageUrl =
        "https://" +
        this.streamUrl.split("//")[1] +
        "/thumbnail.jpg" +
        "?" +
        new Date().getTime(); // To force refresh
      const response = await fetch(imageUrl);

      // If stream is offline we want to use latest cache
      // instead of loading bad one
      if (response.ok) {
        this.stillImage = imageUrl;
        this.stillImageReloadFailures = 0;
      } else {
        this.stillImageReloadFailures++;
        if (this.stillImageReloadFailures > 1) {
          this.onConnectionClosed();
        }
      }
    },
    initPlayer() {
      try {
        console.log("Buffer: " + this.buffer);
        this.sldpPlayer = SLDP.init({
          container: "sldp-player",
          initial_resolution: "1080p",
          stream_url: this.streamUrl,
          buffering: this.buffer,
          autoplay: true,
          muted: true,
          width: "parent",
          aspect_ratio: "16:9",
          adaptive_bitrate: false,
          latency_adjust_method: "seek",
          latency_tolerance: 1800,
        });

        this.sldpPlayer.setCallbacks({
          onError: this.onErrorCallback,
          onConnectionEstablished: this.onConnectionEstablished,
          onLatencyAdjustSeek: this.onLatencyAdjustSeek,
          onLowBuffer: this.onLowBuffer,
          onConnectionClosed: this.onConnectionClosed,
          onVolumeSet: this.onVolumeSet,
        });
        try {
          // This caused wierd looping reload behavior
          // when audio source missing on stream
          // disabling for now
          //this.sldpPlayer.setVolume(this.volume);
        } catch (e) {
          //pass silently
        }
      } catch (error) {
        console.log("Catched error");
        console.log(error);
        this.playerReloads++;
        if (this.playerReloads < 13) {
          this.tryReconnect();
        }
      }
    },
    onErrorCallback(error) {
      console.log(error);
      console.log("error callback");
    },
    onVolumeSet(volume) {
      console.log("Volume set to: " + volume);
      this.volume = volume;
    },
    onConnectionClosed() {
      this.showStillImage = true;
      console.log("Connection closed");

      if (this.loadRetryInterval !== null) {
        clearInterval(this.loadRetryInterval);
      }

      this.loadRetryInterval = setInterval(() => {
        console.log("Set interval for retrying");
        this.tryReconnect();
      }, 3000);
    },
    tryReconnect() {
      console.log("Trying to reconnect");

      try {
        this.sldpPlayer.destroy();
        setTimeout(() => {
          this.initPlayer();
        }, 1200);
      } catch (error) {
        console.log("Error reconnecting");
      }
    },
    onLowBuffer() {
      console.log("Low buffer");
    },
    onConnectionEstablished() {
      console.log("Connection established");

      this.showStillImage = false;
      clearInterval(this.loadRetryInterval);
      setTimeout(() => {
        this.$emit("loading-video", false);
      }, 2000);

      // Some sources give audio issues without a buffer
      // we notice this on initial load so only test this then
      this.connectionRetries += 1;
      if (new Date().getTime() < this.initalLoad + 30000) {
        console.log("Initial load");
        if (this.connectionRetries > 1 && this.buffer < 300) {
          this.buffer = 300;
          console.log(`Buffer increased to ${this.buffer}ms`);
          this.tryReconnect();
        } else if (this.connectionRetries > 3 && this.buffer < 600) {
          this.buffer = 600;
          console.log(`Buffer increased to ${this.buffer}ms`);
          this.tryReconnect();
        } else if (this.connectionRetries > 4 && this.buffer < 1000) {
          this.buffer = 1000;
          console.log(`Buffer increased to ${this.buffer}ms`);
          this.tryReconnect();
        }
      }

      [2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 20000].forEach(
        (delay) => {
          setTimeout(() => {
            this.$emit("handle-resize");
          }, delay);
        }
      );
    },
    onLatencyAdjustSeek(from, to) {
      console.log("Latency seek adjust");
    },
  },
};
</script>

<style scoped>
.tooltip-wrapper .tooltip {
  left: 5px;
}

.tooltip-wrapper {
  float: left !important;
}

.dot {
  animation: dot 1s infinite;
  animation-fill-mode: both;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
</style>
