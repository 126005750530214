<template>
  <div
      class="video-player-skeleton position-relative">
    <div class="card m-0"  id="video-player-skeleton">
      <div class="card-img skeleton video-player">
        <p class="text-white m-auto">{{ message }}</p>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "VideoPlayerSkeleton",
  props:['showTextChat', 'message']
}
</script>

<style scoped>
.video-player-skeleton {
  width: 100%;
  min-height: 30rem;
  height: auto;
}
.card {
  display: flex;
  flex-direction: column;
  flex-basis: 300px;
  flex-shrink: 0;
  flex-grow: 0;
  max-width: 100%;
  height: 100%;
  background-color: #080b15;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  overflow: hidden;
  margin: 1rem;
  padding: 23px;
}


.card-img {
  height: 100%;
}

.card-img img {
  position: absolute;
  width: 100%;
}


.card-title {
  font-size: 1.25rem;
  line-height: 1.33;
  font-weight: 700;
}
.card-title.skeleton {
  min-height: 28px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.card-intro {
  margin-top: 0.75rem;
  line-height: 1.5;
}
.card-intro.skeleton {
  min-height: 72px;
  border-radius: 50px;
  width: 90%;
  margin: auto;
}

.skeleton {
  background-color: rgb(4, 17, 28);
  background-image: linear-gradient(90deg, rgba(5, 35, 61, 0), rgba(5, 35, 61, 0.5), rgba(5, 35, 61, 0));
  background-size: 300px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  -webkit-animation: shine 2s ease infinite;
  animation: shine 2s ease infinite;
  display: flex;
  align-items: center;
  text-align: center;
}

@-webkit-keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}

@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}
.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.card-body {
  padding: 0;
  margin-top: 30px;
}
</style>
