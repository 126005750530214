<template>
  <div
    class="chat-wrapper col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 col-xxl-3 position-relative"
    :class="{ 'chat-wrapper__gap': sessionDescription && !loading && !docked }"
  >
    <Transition>
      <div
        class="chat-component"
        :class="{ 'chat-overlay': docked }"
        v-show="showTextChat"
      >
        <chat-skeleton
          class="w-100"
          v-if="loading && showTextChat && !docked"
        ></chat-skeleton>
        <Conversation
          v-if="conversation && !loading"
          :conversation="conversation"
          :userID="userID"
          :docked="docked"
          @handle-resize="$emit('handle-resize')"
        />
      </div>
    </Transition>

    <button
      v-if="docked"
      class="chat-wrapper__toggle-chat"
      :class="{
        'chat-wrapper__toggle-chat--hidden': !showTextChat,
        'chat-wrapper__unread-messages': unreadTextMessage,
        'chat-wrapper__hide-toggle': getEmojiPickerVisible,
      }"
      @click="toggleCometChat"
    >
      <img
        v-if="!showTextChat"
        src="../../assets/show-chat.png"
        alt="chat-cicrle"
      />
      <img v-else src="../../assets/hide-chat.png" alt="chat-cicrle" />
    </button>
  </div>
</template>

<script>
import { Client as ConversationsClient } from '@twilio/conversations';
import Conversation from './Conversation';
import ChatSkeleton from '@/components/ChatSkeleton.vue';
import { mapGetters } from 'vuex';

export default {
  components: { ChatSkeleton, Conversation },
  props: {
    token: {
      type: String,
      required: true,
    },
    userID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      conversation: null,
      isConnected: false,
      resizeTimeout: null,
      loading: false,
      docked: false,
      isShowMobileChat: false,
      window: {
        width: 0,
      },
    };
  },
  created() {
    this.initConversationsClient();
  },
  mounted() {
    let self = this;
    self.docked = window.innerWidth <= 991;
    self.$store.commit('setShowTextChat', !self.docked);
    self.handleResize();

    setTimeout(function () {
      window.addEventListener('resize', self.handleResize);
    }, 1700);
  },
  computed: {
    ...mapGetters({
      sessionDescription: 'sessionDescription',
      getEmojiPickerVisible: 'chat/getEmojiPickerVisible',
    }),
    showTextChat: {
      get() {
        return this.$store.state.showTextChat;
      },
      set(value) {
        this.$store.commit('setShowTextChat', value);
      },
    },
    unreadTextMessage: {
      get() {
        return this.$store.state.unreadTextMessage;
      },
      set(value) {
        this.$store.commit('setUnreadTextMessage', value);
      },
    },
  },
  methods: {
    async initConversationsClient() {
      this.loading = true;
      window.conversationsClient = ConversationsClient;
      this.conversationsClient = new ConversationsClient(this.token);

      this.conversationsClient.on('initFailed', ({ error }) => {
        console.error('Failed to initialize the Conversations client', error);
        this.loading = false;
      });
      this.conversationsClient.on('initialized', () => {
        console.log('initialized');
        //this.joinedConversation()
        this.loading = false;
      });
      this.conversationsClient.on('conversationJoined', (conversation) => {
        this.conversation = conversation;
        this.loading = false;
      });
      this.conversationsClient.on('connectionStateChanged', (state) => {
        switch (state) {
          case 'connected':
            console.log('connected');
            this.isConnected = true;
            break;
          case 'disconnecting':
            console.log('disconnecting');
            break;
          case 'disconnected':
            console.log('disconnected');
            break;
          case 'denied':
            console.log('denied');
            break;
        }
      });
    },
    handleResize() {
      clearTimeout(this.resizeTimeout);
      if (window.innerWidth !== this.window.width) {
        this.window.width = window.innerWidth;
        this.resizeTimeout = setTimeout(() => {
          if (this.window.width <= 991) {
            this.docked = true;
            this.$store.commit('setShowTextChat', false);
            setTimeout(() => {
              this.$emit('handle-resize');
            }, 700);
          }

          if (this.window.width > 991) {
            this.docked = false;
            setTimeout(() => {
              this.$emit('handle-resize');
            }, 700);
          }
        }, 800);
      }
    },
    toggleCometChat() {
      // Cancel drawing before toggling chat
      this.$store.dispatch('cancelDrawing');

      this.showTextChat = !this.showTextChat;
      this.textChatHiddenByUser = !this.showTextChat;
      if (this.showTextChat) this.unreadTextMessage = false;

      setTimeout(() => {
        this.$emit('handle-resize');
      }, 100);
    },
  },
  watch: {
    showTextChat(value) {
      if (value && this.docked) {
        document.body.classList.add('remove-scroll');
      } else {
        document.body.classList.remove('remove-scroll');
      }
    },
  },
};
</script>

<style lang="scss">
.chat-wrapper {
  &__toggle-chat {
    position: fixed;
    bottom: 5rem;
    left: 50%;
    transform: translate(-50%);
    background: #184059;
    border: none;
    border-radius: 50%;
    padding: 0.75rem;
    cursor: pointer;
    z-index: 1000;
    transition: all 0.4s;
    img {
      width: 24px;
    }

    &--hidden {
      bottom: 1rem;
      left: 2rem;
    }
  }
  &__unread-messages {
    &:before {
      content: '';
      display: block;
      width: 0.75rem;
      height: 0.75rem;
      background: #ff0000;
      border-radius: 50%;
      position: absolute;
      top: -0.15rem;
      right: 0;
    }
  }
  &__hide-toggle {
    display: none !important;
  }
  a {
    color: #42b983;
  }
}
.chat-component {
  display: inline-block;
  width: 100%;
}
.chat-overlay {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100vh;
  overflow: hidden;

  .conversation {
    height: 100%;
    .conversation__chat-wrapper {
      height: 100%;
      max-height: calc(100dvh - 65px);
      overflow: hidden;
      flex: 1 1 0;

      .conversation__chat {
        height: 100%;
      }
    }
  }
}
ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.remove-scroll {
  overflow: hidden;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
