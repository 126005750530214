<template>
<Teleport to="body">
  <div class="open-in-app-drawer">
    <div class="open-in-app-drawer__content content-drawer">
      <div class="content-drawer__header">
        <img class="content-drawer__logo" src="@/assets/logo.png" alt="logo"/>
        <p>Use the Pixelview Player app for a better experience</p>
      </div>
      <div class="content-drawer__actions">
        <a :href="linksConfig.continueInApp.url" class="content-drawer__button content-drawer__continue-in-app">
          {{ linksConfig.continueInApp.text }}
          <img src="@/assets/external-link.png" alt="open app" />
        </a>
        <a :href="linksConfig.downloadApp.url" class="content-drawer__button content-drawer__download-app">
          {{linksConfig.downloadApp.text}}
          <img src="@/assets/downloads.png" alt="download" />
        </a>
      </div>
      <div class="content-drawer__splitter splitter">
        <div class="splitter__line" />
        <span class="splitter__text">OR</span>
      </div>
      <button @click="$store.commit('setDrawerApp', false)" class="content-drawer__button content-drawer__close">
        <span>Watch now online</span>
        <img src="@/assets/right-arrow-filled.png" alt="download" />
      </button>
    </div>
  </div>
</Teleport>
</template>

<script>
export default {
  name: "OpenInAppDrawer",
  data() {
    return {
      linksConfig: {
        continueInApp: {
          text: "Open app",
          url: ''
        },
        downloadApp: {
          text: "Download app",
          url:'https://apps.apple.com/app/pixelview-player/id1570446440'
        },
      }
    }
  },
  created() {
    const url = window.location.href;
    const urlObject = new URL(url);
    const sessionPath = urlObject.pathname + urlObject.search;
    this.linksConfig.continueInApp.url = `pixelview:/${sessionPath}`;
  }
}
</script>

<style lang="scss">
.open-in-app-drawer {
  position: fixed;
  height: 100dvh;
  width: 100vw;
  transition: height 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  padding: 2rem 2.5rem;
  z-index: 2147483647;
  background-color: #050e16;
  background-size: 100% 100%;

  @media (max-width: 776px) {
    padding: 2rem 1rem;
  }

  &__content {
    max-width: 30rem;
    width: 100%;
    overflow-y: auto;
  }

  .content-drawer {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        color: #fff;
        font-weight: 100;
        margin-top: 1rem;
      }
    }


    &__logo {
      max-width: 15rem;
      width: 100%;
    }

    &__button {
      width: 100%;
      display: flex;
      height: 56px;
      column-gap: 0.5rem;
      align-items: center;
      padding: 0.5rem 1rem;
      transition: all 0.2s;
      text-decoration: none;
      font-size: clamp(0.8rem, 2vw, 1rem);
      box-sizing: border-box;
      background: #2e58c7;
      font-family: Avenir, Helvetica, Arial, sans-serif;
      img {
        width: 0.9rem;
        height: 0.9rem;
      }
    }
    &__actions {
      margin-top: 3rem;
      width: 100%;
      max-width: 20rem;
    }

    &__continue-in-app {
      border: 1px solid #2e58c7;
      color: #fff;
      justify-content: center;
      border-radius: 1.25rem;
      &:hover {
        border-color: #0d6efd;
      }
    }

    &__download-app {
      border: 1px solid #2e58c7;
      color: #fff;
      margin-top: 1rem;
      background: #091825;
      justify-content: center;
      border-radius: 1.25rem;
      box-sizing: border-box;
      &:hover {
        background: #2e58c7;
      }
    }


    .splitter {
      margin: 1.5rem 0;
      position: relative;
      &__line {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10rem;
        height: 1px;
        background: #fff;
        display: block;
      }

      &__text {
        background: #fff;
        border: 1px solid #2e58c7;
        color: #2e58c7;
        position: relative;
        z-index: 1;
        font-size: clamp(0.65rem, 1.5vw, 0.75rem);
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-family: Avenir, Helvetica, Arial, sans-serif;
      }
    }

    &__close {
      color: #fff;
      border: 1px solid #2e58c7;
      justify-content: center;
      border-radius: 1.25rem;
      width: 100%;
      max-width: 20rem;
      &:hover {
        border-color: #0d6efd;
      }
      img {
        width: 0.8rem;
        height: 0.8rem;
      }
    }
  }
}
</style>