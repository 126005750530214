<template>
  <div class="iframe-container">
    <iframe
      v-if="videoSrc"
      :src="videoSrc"
      frameborder="0"
      allowfullscreen
      class="responsive-iframe"
    ></iframe>
    <div v-else>
      <p>Invalid pause video URL.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PausePlayer',
  props: ['pauseVideoURL'],
  computed: {
    videoSrc() {
      const url = this.pauseVideoURL;
      let videoId = '';

      // Check for YouTube URLs
      const youtubeMatch = url.match(
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([\w-]{11})/
      );
      if (youtubeMatch && youtubeMatch[1]) {
        videoId = youtubeMatch[1];
        return `https://www.youtube.com/embed/${videoId}?modestbranding=1&controls=0&autoplay=1&mute=1&loop=1&playlist=${videoId}`;
      }

      // Check for Vimeo URLs
      const vimeoMatch = url.match(
        /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:video\/)?(\d+)/
      );
      if (vimeoMatch && vimeoMatch[1]) {
        videoId = vimeoMatch[1];
        return `https://player.vimeo.com/video/${videoId}?loop=1&autoplay=1&mute=1&autopause=0&background=1`;
      }

      console.log('Invalid video URL');
      return '';
    },
  },
};
</script>

<style scoped>
.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
