<template>
  <Teleport to="body">
    <div
      class="file-message__slider slider-image"
    >
      <div
        class="slider-image__overlay"
        @click.stop="$emit('close-overlay')"
      />
      <button v-if="images.length > 1" @click="onPrevSlide" class="slider-nav__prev nav-button">
        <img src="../../assets/left-arrow.png" alt="prev"/>
      </button>
      <div class="slider-image__wrapper">
        <button
          class="slider-image__close"
          @click.stop="$emit('close-overlay')"
        >
          <img
            src="../../assets/close.png"
            alt="close"
          />
        </button>
        <template v-for="(image, index) in images" :key="image">
          <img
            v-if="index === selectedIndex"
            class="slider-image__item"
            :src="image"
            alt="file"
          />
        </template>
      </div>
      <button v-if="images.length > 1" @click="onNextSlide" class="slider-nav__next nav-button">
        <img src="../../assets/right-arrow.png" alt="next"/>
      </button>
      <div v-if="images.length > 1" class="slider-image__nav slider-nav">
        <p class="slider-nav__steps">{{ selectedIndex + 1 }} / {{ images?.length }}</p>
      </div>
    </div>
  </Teleport>
</template>

<script>
export default {
  name: "ChatPreviewSlider",
  props: ["images", 'activeIndex'],
  emits: ['close-overlay'],
  data() {
    return {
      selectedIndex: this.activeIndex || 0,
    };
  },
  methods: {
    onPrevSlide() {
      const totalImagesLength = this.images.length;
      this.selectedIndex = this.selectedIndex === 0 ? totalImagesLength - 1 : this.selectedIndex - 1;
    },
    onNextSlide() {
      const totalImagesLength = this.images.length;
      this.selectedIndex = this.selectedIndex === totalImagesLength - 1 ? 0 : this.selectedIndex + 1;
    }
  }
}
</script>

<style lang="scss">
.slider-image {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1001;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__overlay {
    position: absolute;
    background: rgba(24, 24, 24, 0.5);
    height: 100%;
    width: 100%;
  }
  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: calc(100vw - 15rem);
    max-height: calc(100vh - 20rem);
    min-width: 15rem;
  }

  .slider-nav__prev {
    position: absolute;
    left: 2.5rem;
    top: 50%;
    transition: all 0.2s;
    transform: translateX(-50%);
    transform-origin: center;
    &:hover {
      transform: scale(1.1) translateX(-50%);
    }
  }

  .slider-nav__next {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateX(-50%);
    transition: all 0.2s;
    &:hover {
      transform: translateX(-50%) scale(1.1);
    }
  }

  .nav-button {
    background: #184059;
    padding: 1rem;
    width: 3rem;
    height: 3rem;
    display: flex;
    place-content: center;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
    }

    @media (max-width: 991px) {
      width: 2rem;
      height: 2rem;
      padding: 0.6rem;
    }
  }

  &__close {
    border-radius: 50%;
    padding: 8px;
    position: absolute;
    top: -1.5rem;
    right: -1.5rem;
    width: 3rem;
    height: 3rem;
    display: flex;

    img {
      width: 100%;
      height: 100%;
    }

    @media (max-width: 991px) {
      top: -1rem;
      right: -1rem;
      padding: 4px;
      width: 2rem;
      height: 2rem;
    }

  }

  &__item {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-width: calc(100vw - 15rem);
    max-height: calc(100vh - 20rem);
    min-width: 15rem;
    animation: onShowPreview 0.3s cubic-bezier(0.31, 0.09, 0.21, 0.81);
  }

  .slider-nav {
    position: absolute;
    bottom: 5rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;


    &__steps {
      font-size: 1.25rem;
      padding: 0.5rem 1rem;
      border-radius: 1.25rem;

      @media (max-width: 991px) {
        font-size: 1rem;
      }
    }

  }
}

@keyframes onShowPreview {
  0% {
    transform-origin: center;
    transform: scale(0);
  }
  100% {
    transform-origin: center;
    transform: scale(1);
  }
}

</style>