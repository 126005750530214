<template>
  <div class="tile" :class="{ 'active-speaker': isActiveSpeaker }">
    <audio autoplay playsInline ref="audioRef">
      <track kind="captions" />
    </audio>

    <div class="position-relative">
      <template v-if="participant.video">
        <div class="video-container">
          <video autoplay muted playsInline ref="videoRef"></video>
          <p class="participant-name">{{ username }}</p>
        </div>
      </template>

      <template v-else>
        <no-video-tile :username="username"></no-video-tile>
      </template>
    </div>

    <template v-if="participant.local">
      <call-controls
        :handle-video-click="handleVideoClick"
        :handle-audio-click="handleAudioClick"
        :handle-screenshare-click="handleScreenshareClick"
        :participant="participant"
        :leave-call="leaveCall"
        :disable-screen-share="disableScreenShare"
      />
    </template>
  </div>
</template>

<script>
import CallControls from './CallControls.vue';
import NoVideoTile from './NoVideoTile.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'VideoTile',
  components: {
    CallControls,
    NoVideoTile,
  },
  props: [
    'participant',
    'handleVideoClick',
    'handleAudioClick',
    'handleScreenshareClick',
    'leaveCall',
    'disableScreenShare',
    'isActiveSpeaker',
  ],
  data() {
    return {
      username: 'Guest',
    };
  },
  computed: {
    ...mapGetters(['videoChatAudioMuted']),
  },
  mounted() {
    this.$nextTick(() => {
      this.username = this.participant?.user_name;
      this.handleVideo(this.participant);
      this.handleAudio(this.participant);

      if (this.$refs.videoRef) {
        this.$refs.videoRef.addEventListener(
          'canplay',
          this.handleVideoCanPlay
        );
      }
      if (this.$refs.audioRef) {
        this.$refs.audioRef.addEventListener(
          'canplay',
          this.handleAudioCanPlay
        );
      }
      navigator.mediaDevices.addEventListener('devicechange', this.playTracks);
    });
  },
  watch: {
    videoChatAudioMuted() {
      if (this.videoChatAudioMuted) {
        this.$refs.audioRef.muted = true;
      } else {
        this.$refs.audioRef.muted = false;
      }
    },
  },
  unmounted() {
    if (this.$refs.videoRef && this.handleVideoCanPlay) {
      this.$refs.videoRef.removeEventListener(
        'canplay',
        this.handleVideoCanPlay
      );
    }
    if (this.$refs.audioRef && this.handleAudioCanPlay) {
      this.$refs.audioRef.removeEventListener(
        'canplay',
        this.handleAudioCanPlay
      );
    }
    if (this.playTracks) {
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        this.playTracks
      );
    }
  },
  updated() {
    this.username = this.participant?.user_name;
    // For later optimization, this can be done more selectively
    // using "track-started" and "track-stopped" events.
    this.handleVideo(this.participant);
    this.handleAudio(this.participant);
  },
  methods: {
    handleVideoCanPlay() {
      if (!this.$refs.videoRef) return;
      if (!this.$refs.videoRef.paused) return;
      this.$refs.videoRef.play().catch((e) => {
        if (e instanceof DOMException && e.name === 'NotAllowedError') {
          console.error('Autoplay failed', e);
        } else console.error('Error playing video', e);
      });
    },
    handleAudioCanPlay() {
      if (!this.$refs.audioRef) return;
      if (!this.$refs.audioRef.paused) return;
      this.$refs.audioRef.play().catch((e) => {
        if (e instanceof DOMException && e.name === 'NotAllowedError') {
          console.error('Autoplay failed', e);
        } else console.error('Error playing audio: ', e);
      });
    },
    playTracks() {
      if (this.handleVideoCanPlay) {
        this.handleVideoCanPlay();
      } else {
        console.warn('handleVideoCanPlay is not defined');
      }
      if (this.handleAudioCanPlay) {
        this.handleAudioCanPlay();
      } else {
        console.warn('handleAudioCanPlay is not defined');
      }
    },
    // Add srcObject to video element
    handleVideo() {
      const p = this.participant;

      // If the participant has their video off,
      // early out.
      if (!p?.video) return;

      try {
        const track = p?.tracks?.video?.persistentTrack;
        this.$refs.videoRef.srcObject = new MediaStream([track]);
        this.$refs.videoRef.load();
      } catch (e) {
        console.error('show up a modal to get user gesture', e);
      }
    },

    // Add srcObject to audio element
    handleAudio() {
      const p = this.participant;
      // If the participant is local or has their audio off,
      // early out.
      if (!p || p.local || !p.audio) return;

      try {
        const track = p?.tracks?.audio?.persistentTrack;
        this.$refs.audioRef.srcObject = new MediaStream([track]);
      } catch (e) {
        console.error('show up a modal to get user gesture', e);
      }
    },
  },
};
</script>

<style scoped>
.tile {
  max-width: 160px;
  margin: 5px 5px;
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.3s ease;
}
video {
  width: 160px;
  height: 90px;
  border-radius: 16px;
}

.participant-name {
  position: absolute;
  color: #fff;
  bottom: 8px;
  left: 8px;
  margin: 0;
  padding: 2px 6px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.video-container {
  border-radius: 16px;
  background-color: #050e16;
  max-height: 90px;
  width: 160px;
}

@media screen and (max-width: 575px) {
  .video-container {
    height: 60px;
    width: 100px;
    overflow: hidden;
  }

  video {
    width: 112px;
    height: 65px;
  }

  .tile {
    max-width: 100px;
  }
}

.tile.active-speaker {
  border: 1.5px solid #2e58c7;
}

@media screen and (max-width: 575px) {
  .tile.active-speaker {
    border-width: 2px;
  }
}
</style>
