<template>
  <div class="no-video">
    <p>{{ username }}</p>
  </div>
</template>

<script>
export default {
  name: 'NoVideoTile',
  props: ['username'],
};
</script>

<style>
.no-video {
  background-color: #050e16;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 0;
  border-radius: 16px;
  height: 90px;
  width: 160px;
  color: white;
}

@media screen and (max-width: 575px) {
  .no-video {
    height: 65px;
    width: 100px;
  }
}
</style>
