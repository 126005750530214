<template>
  <label style="color: #686868"> {{ streamInfo }} </label>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'StreamInfo',
  computed: {
    ...mapGetters(['streamInfo']),
  },
};
</script>
