
const state = () => ({
  selectedFiles:[],
  isEmojiPickerVisible: false
})

const getters = {
  getSelectedFiles: state => state.selectedFiles,
  getEmojiPickerVisible: state => state.isEmojiPickerVisible,
}

const mutations = {
  setSelectedFiles(state, files){
    state.selectedFiles = files
  },
  setEmojiPickerVisible(state, value){
    state.isEmojiPickerVisible = value
  }
}

const actions = {
  setSelectedFiles({commit}, files){
    commit('setSelectedFiles', files)
  },
  setEmojiPickerVisible({commit}, value){
    commit('setEmojiPickerVisible', value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
