<template>
  <div class="file-message">
    <div
      v-for="(item, index) in message?.state?.medias"
      :key="index"
      class="file-message__item"
    >
      <div
        class="file-message__pictures pictures"
        :class="{ 'pictures__image--loading': !item?.state?.temporaryUrl }"
      >
        <img
          v-if="item?.state?.temporaryUrl"
          class="pictures__image"
          loading="lazy"
          :src="item?.state?.temporaryUrl"
          alt="file"
        />
        <img class="pictures__loading" v-else src="@/assets/loading.png" alt="loading" />
        <div class="pictures__overlay"  @click="onSelectImage(item,index)">
          <div class="pictures__actions">
            <img
              class="pictures__actions-icon"
              src="../../assets/eye.png"
              alt="eye"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="file-message__text" v-if="messageData.state?.body">
      {{messageData.state?.body}}
    </div>
    <ChatImagePreview
      :selected-overlay-image="selectedOverlayImage"
      @close-overlay="selectedOverlayImage = null"
    />
    <ChatPreviewSlider
      v-if="imagesPreview.images.length"
      :images="imagesPreview.images"
      :active-index="imagesPreview.selectedIndex"
      @close-overlay="resetSelectedImage"
    />
  </div>
</template>

<script>

import ChatImagePreview from "@/components/textchat/ChatImagePreview.vue";
import ChatPreviewSlider from "@/components/textchat/ChatPreviewSlider.vue";

export default {
  components: {ChatPreviewSlider, ChatImagePreview},
  props: ['messageData'],
  name: "ChatMessageFile",
  data() {
    return{
      selectedOverlayImage: null,
      imagesPreview: {
        images: [],
        selectedIndex: null
      },
      message: {...this.messageData}
    }
  },
  methods: {
    async generateTemporaryUrl (medias) {
      let temporaryMedias = [...medias]
      temporaryMedias = await Promise.all(temporaryMedias.map(async (media) => {
        const url = async () => await media?.getContentTemporaryUrl()
        const generatedUrl = await url()
        return Object.assign(media, {
          state: { temporaryUrl: generatedUrl }
        })
      }))
      this.message.state.medias = temporaryMedias
    },
    onSelectImage(file, index) {
      this.imagesPreview.selectedIndex = index;
      this.imagesPreview.images = this.message.state.medias.map(el => el.state?.temporaryUrl)
    },
    resetSelectedImage() {
      this.imagesPreview = {
        images: [],
        selectedIndex: null
      }
    }
  },
  mounted() {
    this.generateTemporaryUrl(this.message?.state?.medias)
  }
}
</script>

<style lang="scss">
.chat-message-default {
  .file-message {
    align-items: flex-end;
    text-align: end;
  }
}
.chat-message-mine {
  .file-message {
    align-items: flex-start;
    text-align: start;
  }
}
.file-message {
  border-radius: 8px;
  background: #374459;
  padding: 6px;
  display: flex;
  flex-direction: column;
  row-gap: 6px;

  &__text {
    margin: 0;
    width: 100%;
    line-height: 1;
    word-break: break-word;
  }

  &__item {
    width: 100%;
  }


  &__pictures {
    max-width: 15rem;
    max-height: 15rem;
    cursor: pointer;
    position: relative;
  }

  .pictures {
    width: 100%;
    height: 5rem;
    min-width: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;

      &--loading {
        border-radius: 0.5rem;
        background: rgba(0,0,0,0.5);
      }
    }

    &__loading {
      width: 2rem;
      height: 2rem;
      animation: 1s linear infinite loading;
    }

    &__overlay {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 6px;
      width: 100%;
      height: 100% ;
      top: 0;
      left: 0;
      position: absolute;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s linear 300ms, opacity 300ms;
    }

    &__actions {
      position: absolute;
      bottom: 0.1rem;
      left: 0.25rem;
      display: flex;
      align-items: center;
    }

    &__actions-icon {
      @apply transition-all w-5 h-5;
      transition: all;
      width: 1.25rem;
      height: 1.25rem;
    }

    &:hover {
      .pictures__overlay {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 300ms;
      }
    }
  }
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  } 100% {
  transform: rotate(360deg);
      }
}
</style>
