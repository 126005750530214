<template>
  <div  class="d-none d-lg-block d-xl-block d-xxl-block col-lg-4 col-xl-3 col-xxl-3 position-relative chat-section">
    <div class="card" :style="{height: heightChatSkeleton} ">
      <div class="card-body">
        <h2 class="card-title skeleton">
        </h2>
        <p class="card-intro skeleton">
        </p>
        <p class="card-intro skeleton w-50">
        </p>
        <p class="card-intro skeleton w-75">
        </p>

        <h2 class="card-title skeleton">
        </h2>
        <p class="card-intro skeleton">
        </p>
        <p class="card-intro skeleton w-50">
        </p>
        <p class="card-intro skeleton w-75">
        </p>

        <h2 class="card-title skeleton">
        </h2>
        <p class="card-intro skeleton">
        </p>
        <p class="card-intro skeleton w-50">
        </p>
        <p class="card-intro skeleton w-75">
        </p>

        <h2 class="card-title skeleton">
        </h2>
        <p class="card-intro skeleton">
        </p>
        <p class="card-intro skeleton w-50">
        </p>
        <p class="card-intro skeleton w-75">
        </p>

        <h2 class="card-title skeleton">
        </h2>
        <p class="card-intro skeleton">
        </p>
        <p class="card-intro skeleton w-50">
        </p>
        <p class="card-intro skeleton w-75">
        </p>

        <h2 class="card-title skeleton">
        </h2>
        <p class="card-intro skeleton">
        </p>
        <p class="card-intro skeleton w-50">
        </p>
        <p class="card-intro skeleton w-75">
        </p>

        <h2 class="card-title skeleton">
        </h2>
        <p class="card-intro skeleton">
        </p>
        <p class="card-intro skeleton w-50">
        </p>
        <p class="card-intro skeleton w-75">
        </p>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "ChatSkeleton",
  data() {
    return {
      heightChatSkeleton: '',
    };
  },
  mounted() {
    let videoPlayerSection = document.getElementById('video-player-section');
    let videoPlayerSkeleton = document.getElementById('video-player-skeleton');
    this.heightChatSkeleton = videoPlayerSkeleton != null ? videoPlayerSkeleton.clientHeight+ 5 + 'px' : videoPlayerSection.clientHeight + 'px'
  }
}
</script>

<style scoped>
.card {
  display: flex;
  flex-direction: column;
  flex-basis: 300px;
  flex-shrink: 0;
  flex-grow: 0;
  max-width: 100%;
  background-color: #080b15;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  overflow: hidden;
  margin: 0rem;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.card-title {
  font-size: 1.25rem;
  line-height: 1.33;
  font-weight: 700;
}
.card-title.skeleton {
  min-height: 20px;
  border-radius: 20px;
}

.card-intro {
  margin-top: 0.75rem;
  line-height: 1.5;
}
.card-intro.skeleton {
  min-height: 10px;
  border-radius: 50px;
}

.skeleton {
  background-color: rgb(4, 17, 28);
  background-image: linear-gradient(90deg, rgba(5, 35, 61, 0), rgba(5, 35, 61, 0.5), rgba(5, 35, 61, 0));
  background-size: 300px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  -webkit-animation: shine 2s ease infinite;
  animation: shine 2s ease infinite;
}

@-webkit-keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}

@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}
.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.card-body{
  overflow: hidden;
}
</style>
